<template>
  <ClientOnly>
    <StatusPage>
      <template #icon><IconMailBrand /></template>
      <template #title>Подтвердите свой E-mail</template>
      <template #desc>Мы отправили ссылку для подтверждения аккаунта на указанный e-mail {{ email }}. Ссылка действует 24 часа.</template>
      <template #hint>Не забудьте проверить папку “Спам”, если письмо не пришло.</template>
      <template #actions>
        <BaseButton color-primary @click="resend" :class="{ disabled: !isActive }">Отправить ссылку повторно</BaseButton>
        <div v-if="!isActive">{{ formatTimeMs(delay) }}</div>
      </template>
    </StatusPage>
  </ClientOnly>
</template>

<script setup>
import { resendEmail } from '@/api/auth'
import StatusPage from '@/components/layouts/StatusPage'
import IconMailBrand from '@/components/icons/IconMailBrand'
import BaseButton from '@/components/common/BaseButton'
import useActionDelay from '~/composables/useActionDelay'
import { formatTimeMs } from '~/utils/formatMs'

useHead({
  title: 'Подтверждение E-mail'
})

const email = process.client ? localStorage.getItem('registerEmail') : null

const {
  action: resend,
  isActive,
  delay
} = useActionDelay(
  () => resendEmail({ email }),
  60000,
  { immediate: true, localStorageKey: 'confirm-page-delay' }
)
</script>
